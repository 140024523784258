<template>
    <client-page>

        <sub-visual :tit="this.$route.query.code" bg="/images/sub/visual/sv--product.jpg"></sub-visual>

        <section class="section">
            <v-container>
                <v-row>
                    <v-col cols="6" md="3" v-for="program, idx in programs" :key="idx">
                        <card-primary>
                            <div class="thumb">
                                <div class="thumb__inner" style="background-image: url(/images/test2.jpg)"></div>
                            </div>
                            <div class="w-100 pa-20 pa-md-30">
                                <h5 class="tit-txt font-weight-bold line-height-13 word-keep-all">
                                    {{program.groups}}
                                </h5>
                            </div>
                        </card-primary>
                    </v-col>
                </v-row>

                <div class="v-pagination-wrap">
                    <v-pagination v-model="page" :length="pageCount" @input="search"/>
                </div>

            </v-container>
        </section>

        <!-- <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit tit--lg">{{this.$route.query.code}}</h2>
            </div>
            <div v-for="program, idx in programs" :key="idx">
                <v-card>
                    <v-card-subtitle>{{program.groups}}시리즈</v-card-subtitle>
                    <v-card-title>{{program.subject}}</v-card-title>
                    <v-divider />
                    <v-content>{{program.content}}</v-content>
                </v-card>
            </div>
        </v-container> -->
        
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/en/templates/ClientPage.vue";
import SubVisual from "@/components/client/en/sub/sub-visual.vue";
import CardPrimary from "@/components/dumb/card-primary.vue";

import api from "@/api";

export default {
    components: {
        ClientPage,
        SubVisual,
        CardPrimary,
    },
    data() {
        return {
            programs: [],
            code: this.$route.query.code,

            page: 1,
            pageCount: 0,
			limit: 8,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.code = this.$route.query.code;
            let { boards } = await api.v1.boards.gets({ params: { category: this.code, language: "en" }}); 
            this.programs = boards;
        }
    },
    watch: {
        "$route.query.category"() {
            this.init();
        }
    }
};
</script>
